import React from "react"
import Headline from "../components/headline"
import Layout from "../components/layout"
import { graphql, Link } from "gatsby"

const News = ({ data: { allWpPost } }) => {
  return (
    <Layout>
      <Headline title="News & Events" excerpt="" />
      <section className="section">
        <div className="container">
          <div className="blog-list">
            {allWpPost.nodes &&
              allWpPost.nodes.map(post => (
                <Link to={`/news/${post.slug}`}>
                  <img
                    src={post.featuredImage.node.mediaItemUrl}
                    style={{
											width: "100%",
											marginBottom: "1rem",
											aspectRatio: "3/2",
											objectFit: "cover",
                    }}
                    alt=""
                  />
                  <h5 className="is-size-3 has-text-black has-text-weight-light">
                    {post.title}
                  </h5>
                  <span style={{ color: "#999" }}>{post.date}</span>
                  <br />
                  <br />
                  <p
                    className="is-size-5 has-text-black"
                    dangerouslySetInnerHTML={{
                      __html: post.excerpt.substring(0, 144),
                    }}
                  ></p>
                  <br />
                  <span className="is-size-6 has-text-primary">
                    Read More >>
                  </span>
                </Link>
              ))}
          </div>
        </div>
      </section>
    </Layout>
  )
}

export const query = graphql`
  {
    allWpPost(sort: { fields: [date], order: DESC }) {
      nodes {
        date(formatString: "LL")
        title
        excerpt
        slug
        featuredImage {
          node {
            mediaItemUrl
          }
        }
      }
    }
  }
`

export default News
